
import { defineComponent, onMounted, ref } from "vue";
import Avaliacao from "@/interfaces/Avaliacao";
import BaseService from "@/services/admin/BaseService";
import { useRoute } from "vue-router";
import store from "@/store";
import Disciplina from "@/interfaces/Disciplina";
import DivisaoService from "@/services/auth/DivisaoService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  props: { id: { type: String, required: false } },
  setup() {
    const route = useRoute();
    const $q = useQuasar();
    const loading = ref<boolean>(false);
    const loadingButton = ref<boolean>(false);
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const avaliacao = ref<Avaliacao>();
    const showAnexo = ref<boolean>(false);
    const showModal = ref<boolean>(false);
    const token = ref<string>();
    const urlApi = ref<string>(process.env.VUE_APP_API_BASE);
    const respostaAnexada = ref();
    const avaliacaoShow = ref<boolean>(false);
    const resolucaoUrl = ref<string>("");
    const parametros = ref<any>([]);
    const disciplinas = ref<Disciplina[]>([]);

    const getDisciplinas = (): void => {
    loading.value = true;
    BaseService.list("aluno/disciplinas-com-avaliacao")
        .then((res) => {
          disciplinas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
  };

    const openModal = (): void => {
    showModal.value = true;
  };

    const showNotify = (opts: any): void => {
    $q.notify(opts);
  };

    const verResolucao = (a: any): void => {
    avaliacaoShow.value = true;
    resolucaoUrl.value = a;
  };

    const anexarCartao = (): void => {
    loadingButton.value = true;
    const formData = new FormData();
    formData.append(
      "anexo_cartao",
      respostaAnexada.value ? respostaAnexada.value : ""
    );
    formData.append(
      "avaliacao_id",
      avaliacao.value?.id ? avaliacao.value.id : ""
    );
    BaseService.store(
      `aluno/minhas-avaliacoes/enviar-cartao-resposta`,
      formData
    )
        .then((res) => {
          showNotify({
            type: "positive",
            message: "Cartão resposta enviada com sucesso",
          });
          getAvaliacao();
          showModal.value = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 422) {
            Object.entries(err.response.data.errors).forEach(([key, value]) => {
              const valueMessage: Array<string> = value as Array<string>;
              showNotify({
                type: "negative",
                message: valueMessage[0],
              });
            });
          } else {
            showNotify({
              type: "negative",
              message: "Erro ao enviar resposta",
            });
          }
        })
        .finally(() => {
          loadingButton.value = false;
        });
  };

    const getAvaliacao = (): void => {
    avaliacao.value = undefined;
    loading.value = true;
    BaseService.list(`aluno/minhas-avaliacoes/${route.params.id}`)
        .then(({ data }) => {
          if (data?.nota?.parametros) {
            parametros.value = JSON.parse(data?.nota?.parametros);
          }
          avaliacao.value = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (loading.value = false));
  };

    const redirectTo = (url: string): void => {
    window.open(url, "_blank");
  };

    const getCorrecao = (avaliacao: string): void => {
    console.log(avaliacao);
  };

    onMounted(() => {
      getDisciplinas();
      getAvaliacao();
    });

    return {
      loading,
      url,
      avaliacao,
      getAvaliacao,
      route,
      showAnexo,
      urlApi,
      token,
      showModal,
      openModal,
      respostaAnexada,
      loadingButton,
      anexarCartao,
      redirectTo,
      getCorrecao,
      avaliacaoShow,
      verResolucao,
      resolucaoUrl,
      parametros,
      disciplinas,
    };
  },
});
